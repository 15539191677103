<i18n>
ru:
  changeCity: Нет, Вы ошиблись
  confirmCity: Да, верно
  description: Правильный выбор города влияет на отображение акций и товаров
  identified: 'Вы в городе '
  yourCity: Ваш город
ua:
  changeCity: Ні, Ви помилилися
  confirmCity: Так правильно
  description: Правильний вибір міста впливає на відображення акцій та товарів
  identified: 'Ваше місто '
  yourCity: Ваше місто
us:
  changeCity: No it's not
  confirmCity: Yes, that's right
  description: The correct choice of the city affects the display of promotions and
    products
  identified: 'Your city is '
  yourCity: Your city is
</i18n>

<template>
  <div class="v-w-100 v-d-flex v-flex-column">
    <div
      v-if="!appConfig.VueSettingsPreRun.CitySelectHideWhenLessThanTwo || citiesList.length > 1"
      class="v-current-rest"
      :class="{
        'v-pointer v-underline-rest': citiesList.length > 1,
        'v-pb-xs':
          appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar' &&
          !compact &&
          appConfig.VueSettingsPreRun.HeaderEnableAddresses &&
          externalLinksContacts.length > 0
      }"
    >
      <icon-address-mark />
      <span
        v-if="isDesktopVersion"
        v-html="translate('citySelector.yourCity')"
      />

      <div
        v-if="citiesList.length === 0"
        class="v-current-rest-name v-one-city"
      >
        <span
          class="v-current-rest-text"
          v-html="sanitize(cityName)"
        />
      </div>

      <div
        v-else-if="citiesList.length > 6"
        class="v-current-rest-name"
      >
        <template v-if="appConfig.VueSettingsPreRun.CitySelectShowConfirm">
          <div
            v-if="!closedCurrentCityMatches"
            id="v-current-city-matches-wrapper"
            data-test-id="current-city"
          >
            <div class="v-title v-mb-sm">
              <span v-html="translate('citySelector.identified')" />
              <span
                class="v-current-rest-text"
                v-html="`${sanitize(cityName)}?`"
              />
            </div>
            <div class="v-current-city-matches-desc v-mb-sm">
              <span v-html="translate('citySelector.description')" />
            </div>
            <div class="v-button-block">
              <arora-button
                class="v-mr-sm"
                :label="translate('citySelector.confirmCity')"
                data-test-id="current-city-btn-yes"
                @click="close"
              />
              <arora-button
                class-name="v-btn-text"
                ignore-settings
                :label="translate('citySelector.changeCity')"
                @click="changeCity"
              />
            </div>
          </div>
        </template>
        <span
          class="v-d-flex v-align-items-center"
          @click="changeCity"
        >
          <span
            class="v-current-rest-text"
            v-html="cityName"
          />
          <icon-general-caret-down />
        </span>
      </div>

      <div
        v-else-if="citiesList.length < 7"
        class="v-current-rest-name--by-click v-d-flex v-align-items-center"
        @click="cityListWhenShowOnClick"
      >
        <span v-html="sanitize(cityName)" />
        <icon-general-caret-down />
        <transition
          appear
          mode="out-in"
          name="slidedown"
        >
          <ul
            v-if="showList"
            class="v-city-select-list--by-click"
          >
            <li
              v-for="rest in citiesList"
              :key="sanitize(rest.ID)"
              class="v-city-item"
              :class="{ 'v-active': rest.ID === appConfig.CurrentRestaurantId }"
            >
              <arora-nuxt-link
                class-name="v-city-link"
                external-link
                :href="makeLink(rest)"
                :label="sanitize(rest.Name)"
              />
            </li>
          </ul>
        </transition>
      </div>
    </div>
    <div
      v-if="
        appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar' &&
        showPhones &&
        appConfig.VueSettingsPreRun.HeaderEnableAddresses &&
        externalLinksContacts.length > 0
      "
      class="v-additional-header-info v-d-flex v-flex-column"
    >
      <span
        v-if="!compact"
        class="v-cursor-default v-mb-xs"
        v-html="sanitize(externalLinksContacts[0].Name)"
      />
      <address
        v-if="!stringIsNullOrWhitespace(externalLinksContacts[0].Url)"
        class="v-d-flex v-flex-column v-address-mark-link--phone"
      >
        <arora-nuxt-link
          v-for="(phone, index) in externalLinksContacts[0].Url.split(',')"
          :key="`header-wold-phone-${index}`"
          class-name="v-mb-xxs v-pointer v-font-weight-600"
          external-link
          :href="`tel:+${phoneBeautified(sanitize(phone), 'header').replace(/\D+/g, '')}`"
          :label="phoneBeautified(sanitize(phone), 'header')"
        />
      </address>
      <span
        v-if="!compact && !stringIsNullOrWhitespace(externalLinksContacts[0].Additional)"
        class="v-font-weight-500 v-opacity-50"
        v-html="sanitize(externalLinksContacts[0].Additional)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Restaurant } from '~types/addressStore'
import type { CitySelect, ExternalLink } from '~types/settingsVariants'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

withDefaults(
  defineProps<{
    compact?: boolean
    showPhones?: boolean
    externalLinksContacts: ExternalLink[]
  }>(),
  {
    compact: false,
    showPhones: true
  }
)

const appConfig = useAppConfig()
const addressStore = useAddressStore()
const popupStore = usePopupStore()

const { cityName, makeLink } = useAddressSelector()
const closedCurrentCityMatches = useCookie('closedCurrentCityMatches')
const { isDesktopVersion } = useWindowSize()
const { phoneBeautified } = usePhoneNumber()
const { stringIsNullOrWhitespace } = useCommon()
const { sanitize, translate } = useI18nSanitized()

const showList = ref<boolean>(false)

function cityListWhenShowOnClick(): void {
  showList.value = !showList.value
}

onMounted(async () => {
  if (import.meta.client) {
    await addressStore.initRestaurant()

    if (
      appConfig.VueSettingsPreRun.CitySelectShowOnEntrance &&
      citiesList.value &&
      (!appConfig.VueSettingsPreRun.CitySelectShowCloseButton ||
        (!appConfig.VueSettingsPreRun.CitySelectShowConfirm && citiesList.value.length > 6))
    )
      await addressStore.showCityPopupAtEntrance({
        fromCityPopup: window.location.search.includes('fromCityPopup=true'),
        listCity: appConfig.VueSettingsPreRun.CitySelectDisplay as CitySelect,
        showCloseButton: appConfig.VueSettingsPreRun.CitySelectShowCloseButton,
        showPopupOnce: appConfig.VueSettingsPreRun.CitySelectShowOnce
      })
  }
})

const citiesList = computed(() => {
  const restaurants: Restaurant[] | null = addressStore.Restaurants.data
  let total = [] as Restaurant[]

  const externalLinksAddress = appConfig.VueSettingsPreRun.ExternalLinks.filter(
    (l) => l.Type === 'address'
  )

  if (externalLinksAddress.length > 0)
    for (const link of externalLinksAddress) {
      total.push({
        Active: true,
        ExternalLink: link.Url,
        ID: '' as GUID,
        Name: link.Name ?? '',
        ShowRestaurantInSelect: true,
        SortWeight: link.SortWeight
      })
    }

  if (restaurants) {
    total = [
      ...total,
      ...Object.values(restaurants).filter((rest) => {
        return rest.Active && rest.ShowRestaurantInSelect
      })
    ]
  }

  return total
})

async function changeCity(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: appConfig.VueSettingsPreRun.CitySelectShowCloseButton,
    popupName: 'citySelectorPopup'
  })
}

function close(): void {
  closedCurrentCityMatches.value = 'true'
}
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-current-rest {
  padding: 10px;
  cursor: default;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  color: variables.$HeaderColor;
  gap: 4px;
  font-size: 1.15rem;
  position: relative;

  svg {
    width: 18px;
    height: auto;
    position: relative;
    fill: variables.$SecondaryBackgroundColor;

    path {
      fill: variables.$SecondaryBackgroundColor;
    }
  }
}

.v-one-city {
  pointer-events: none;
  cursor: default;
}

.v-current-rest-name {
  display: flex;
  gap: 2px;
  cursor: pointer;
  align-items: baseline;
  position: relative;
}

.v-underline-rest {
  position: relative;
}

.v-current-rest-name--by-click {
  .fa-caret-down {
    color: variables.$SecondaryBackgroundColor;
    margin-left: 5px;
  }

  .v-city-select-list--by-click {
    box-shadow: variables.$FloatShadow;
    position: absolute;
    z-index: 1060;
    top: 100%;
    left: 30px;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    max-width: 225px;
    width: fit-content;
    background: variables.$HeaderBackgroundColorContrast5;
    border-radius: variables.$BorderRadius;

    .v-city-item {
      @include mixins.trans;
      padding: 10px 20px;

      &.v-active {
        pointer-events: none;
        cursor: default;

        .v-city-link {
          color: variables.$PrimaryBackgroundColor;
        }
      }

      .v-city-link {
        color: variables.$HeaderColor;
        display: flex;
        text-decoration: none;
        text-align: left;

        overflow: hidden;
        white-space: pre-wrap;
        text-overflow: ellipsis;
      }

      &:hover {
        background: variables.$HeaderBackgroundColorContrast5;
      }
    }
  }
}

#v-current-city-matches-wrapper {
  padding: 16px;
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  width: 300px;
  position: absolute;
  z-index: 1005;
  left: -50%;
  top: 100%;
  .v-title {
    gap: 5px;
    display: flex;
  }
  .v-current-city-matches-desc {
    font-size: 1rem;
  }

  @include mixins.sm {
    position: fixed;
    top: 50px;
    left: 15px;
    right: 15px;
    width: auto;
  }
}

.v-additional-header-info {
  margin-left: 1.8rem;
}
</style>
